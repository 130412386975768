<ng-container *transloco="let t">
  <ng-container *ngIf="favoriteItem.oArticle; else notExists">
    <td class="w-full md:w-table-article pr-8 md:pr-2 p-2 align-middle text-xs md:text-sm">
      <p class="hyphens-auto font-bold">
        @if (favoriteItem.oArticle.shtStatus !== 2 && !favoriteItem.oArticle.isDBLoaded) {
          <a class="hover:text-primary" [routerLink]="['article' | urlPath, favoriteItem.oArticle.sArticleID]">{{
            favoriteItem.oArticle.sName
          }}</a>
        } @else {
          {{ favoriteItem.oArticle.sName }}
        }
      </p>
      <p>
        {{
          t("core.header.flyout.favorite.item.article-id", {
            articleId: favoriteItem.oArticle.sUniformNumber || favoriteItem.oArticle.sArticleID,
          })
        }}
      </p>
    </td>
    <td class="w-full md:w-table-total p-2 align-middle text-xs md:text-sm">
      <p class="font-bold">
        <app-article-price [article]="favoriteItem.oArticle" (zeroPrice)="priceIsZero = $event"></app-article-price>
      </p>
    </td>
    <td class="w-1/2 md:w-130px p-2 align-middle text-xs md:text-sm">
      <ng-container *ngIf="!priceIsZero && favoriteItem.oArticle.shtStatus !== 2 && !favoriteItem.oArticle.isDBLoaded">
        <div class="md:-mt-4">
          <app-quantity-input
            [quantity]="favoriteItem.decQuantity"
            [article]="favoriteItem.oArticle"
            (onQuantityChange)="onQuantityChange(favoriteItem, $event)"
          ></app-quantity-input>
        </div>
      </ng-container>
    </td>
    <td class="w-1/2 md:w-160px p-2 align-middle text-xs md:text-sm">
      <ng-container *ngIf="!priceIsZero && favoriteItem.oArticle.shtStatus !== 2 && !favoriteItem.oArticle.isDBLoaded">
        <app-icon-button
          (click)="onAddArticleToCartClick(favoriteItem)"
          [background]="'primary'"
          [hover]="'secondary'"
          [iconPosition]="'right'"
          [icon]="store.isCartLoading() ? 'loading' : 'cart'"
          [textColor]="'white'"
          [text]="t('core.header.flyout.favorite.item.buy')"
          [ngClass]="store.isCartLoading() ? 'pointer-events-none' : ''"
        ></app-icon-button>
      </ng-container>
    </td>
  </ng-container>
  <ng-template #notExists>
    <td colspan="4">{{ t("core.header.flyout.favorite.item.not-exists") }}</td>
  </ng-template>

  <td class="absolute top-4 md:top-0 right-0 md:relative md:w-40px py-0.5 md:p-2 align-middle">
    <div class="flex items-center justify-center">
      <button (click)="deleteFavoriteItem(favoriteItem)" type="button" class="hover:text-primary">
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  </td>
</ng-container>
